export default [
  {
    id: 1,
    name: 'Неврологи'
  },
  {
    id: 2,
    name: 'Кардиологи'
  },
  {
    id: 3,
    name: 'Терапевты'
  },
  {
    id: 4,
    name: 'Онкологи'
  },
  {
    id: 5,
    name: 'Гинекологи'
  },
  {
    id: 6,
    name: 'Потребители'
  },
]