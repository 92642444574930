<template>
	<div id="page_projects">
		<section_projects />
	</div>
</template>

<script>
	import section_projects from '../components/section_projects.vue';

	export default {
    name: 'page_projects',
		components: {
			section_projects
		},
	};
</script>
