export default [
  {
    id: 1,
    name: 'НПВС'
  },
  {
    id: 2,
    name: 'Ноотроп'
  },
  {
    id: 3,
    name: 'ХСН'
  },
  {
    id: 4,
    name: 'СС-риски'
  },
  {
    id: 5,
    name: 'Рак разного генома'
  },
  {
    id: 6,
    name: 'Вирусные заболевания'
  },
  {
    id: 7,
    name: 'Кандидоз'
  },
  {
    id: 8,
    name: 'КОК'
  },
  {
    id: 9,
    name: 'Гигиена'
  },
  {
    id: 10,
    name: 'Здоровье полости рта'
  },
  {
    id: 11,
    name: 'Витамины'
  },
  {
    id: 12,
    name: 'Другое'
  },
]