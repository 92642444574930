<template>
	<section class="section_pr">
		<div class="grid">
			<div class="section__content">
				<div class="grid__row">
					<div class="grid__col _lg_12">
						<p class="title _lg text-white text-margin">
							<span class="text-blue">Продуктовый</span> и
							<span class="text-blue">благотворительный PR</span>
						</p>
						<p class="title _md text-white text-margin text-light">
							Подбираем <span class="text-semi">комплаентную</span> стратегию продвижения
						</p>
					</div>
				</div>
			</div>
			<div class="section__content">
				<div class="grid__row _lg_content_end">
					<div
						class="grid__col _lg_3 _sm_12 animation _type_slideInLeft"
						data-duration="0.5"
						data-delay="0.8"
					>
						<p class="text-pink title _lg">01</p>
						<p class="title _md text-white">
							Работа со СМИ и новыми медиа
						</p>
					</div>
					<div class="grid__col _lg_2 _sm_0"></div>
					<div
						class="grid__col _lg_4 _sm_12 animation _type_slideInLeft"
						data-duration="0.5"
						data-delay="4"
					>
						<p class="text-pink title _lg">0<span v-if="!isMobile">3</span><span v-if="isMobile">2</span></p>
						<p class="title _md text-white">
							Коммуникация с профессиональными коммьюнити
						</p>
					</div>
				</div>
			</div>
			<div id="svg-container"></div>
			<div class="section__content">
				<div class="grid__row">
					<div class="grid__col _lg_6 _sm_0"></div>
					<div
						class="grid__col _lg_3 _sm_12 animation _type_slideInLeft"
						data-duration="0.5"
						data-delay="2.7"
					>
						<p class="text-pink title _lg">0<span v-if="isMobile">3</span><span v-if="!isMobile">2</span></p>
						<p class="title _md text-white">
							Работа с блогерами
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section__content" id="svg">
			<svg
				width="1499"
				height="264"
				viewBox="0 0 1499 264"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Group 111">
					<g id="sounds">
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.1"
							id="Rectangle 4.1"
							x="233.915"
							y="148.065"
							width="15.8631"
							height="9.91446"
							rx="4.95723"
							transform="rotate(90 233.915 148.065)"
							fill="url(#paint0_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.2"
							id="Rectangle 4.2"
							x="252.752"
							y="141.125"
							width="28.7519"
							height="8.92302"
							rx="4.46151"
							transform="rotate(90 252.752 141.125)"
							fill="url(#paint1_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.3"
							id="Rectangle 4.3"
							x="272.581"
							y="130.219"
							width="50.5638"
							height="9.91446"
							rx="4.95723"
							transform="rotate(90 272.581 130.219)"
							fill="url(#paint2_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.4"
							id="Rectangle 4.4"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 282.397 131.294)"
							fill="url(#paint3_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.5"
							id="Rectangle 4.5"
							x="311.595"
							y="131.294"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 311.595 131.294)"
							fill="url(#paint4_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.6"
							id="Rectangle 4.6"
							x="331.061"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 331.061 121.561)"
							fill="url(#paint5_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.7"
							id="Rectangle 4.7"
							x="350.527"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 350.527 121.561)"
							fill="url(#paint6_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.8"
							id="Rectangle 4.8"
							width="126.527"
							height="9.73283"
							rx="4.86642"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 360.26 92.3621)"
							fill="url(#paint7_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="0.9"
							id="Rectangle 4.9"
							x="389.458"
							y="82.6294"
							width="145.992"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 389.458 82.6294)"
							fill="url(#paint8_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1"
							id="Rectangle 4.10"
							x="408.924"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 408.924 102.095)"
							fill="url(#paint9_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.1"
							id="Rectangle 4.11"
							x="428.389"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 428.389 111.828)"
							fill="url(#paint10_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.2"
							id="Rectangle 4.12"
							width="68.1298"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 438.122 121.561)"
							fill="url(#paint11_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.3"
							id="Rectangle 4.13"
							x="467.321"
							y="131.294"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 467.321 131.294)"
							fill="url(#paint12_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.4"
							id="Rectangle 4.14"
							x="486.786"
							y="141.027"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 486.786 141.027)"
							fill="url(#paint13_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.5"
							id="Rectangle 4.15"
							x="506.252"
							y="141.027"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 506.252 141.027)"
							fill="url(#paint14_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.6"
							id="Rectangle 4.16"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 515.985 141.027)"
							fill="url(#paint15_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.7"
							id="Rectangle 4.17"
							x="545.183"
							y="131.294"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 545.183 131.294)"
							fill="url(#paint16_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.8"
							id="Rectangle 4.18"
							x="564.649"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 564.649 111.828)"
							fill="url(#paint17_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="1.9"
							id="Rectangle 4.19"
							x="584.114"
							y="92.3621"
							width="126.527"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 584.114 92.3621)"
							fill="url(#paint18_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2"
							id="Rectangle 4.20"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 593.847 131.294)"
							fill="url(#paint19_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.1"
							id="Rectangle 4.21"
							x="623.046"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 623.046 102.095)"
							fill="url(#paint20_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.2"
							id="Rectangle 4.22"
							x="642.511"
							y="131.294"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 642.511 131.294)"
							fill="url(#paint21_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.3"
							id="Rectangle 4.23"
							x="661.977"
							y="131.294"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 661.977 131.294)"
							fill="url(#paint22_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.4"
							id="Rectangle 4.24"
							width="68.1298"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 671.71 121.561)"
							fill="url(#paint23_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.5"
							id="Rectangle 4.25"
							x="700.908"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 700.908 111.828)"
							fill="url(#paint24_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.6"
							id="Rectangle 4.26"
							x="720.374"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 720.374 111.828)"
							fill="url(#paint25_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.7"
							id="Rectangle 4.27"
							x="739.84"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 739.84 121.561)"
							fill="url(#paint26_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.8"
							id="Rectangle 4.28"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 749.572 131.294)"
							fill="url(#paint27_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.1"
							id="Rectangle 4.29"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 827.435 102.095)"
							fill="url(#paint28_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3"
							id="Rectangle 4.30"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 807.97 111.828)"
							fill="url(#paint29_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.9"
							id="Rectangle 4.31"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 788.504 121.561)"
							fill="url(#paint30_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="2.8"
							id="Rectangle 4.32"
							x="778.771"
							y="131.294"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="rotate(90 778.771 131.294)"
							fill="url(#paint31_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.3"
							id="Rectangle 4.33"
							x="856.634"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 856.634 111.828)"
							fill="url(#paint32_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.4"
							id="Rectangle 4.34"
							x="876.099"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 876.099 121.561)"
							fill="url(#paint33_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.5"
							id="Rectangle 4.35"
							x="895.565"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 895.565 121.561)"
							fill="url(#paint34_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.6"
							id="Rectangle 4.36"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-5.3425e-08 1 1 3.57639e-08 905.298 131.294)"
							fill="url(#paint35_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.7"
							id="Rectangle 4.37"
							x="934.496"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 934.496 102.095)"
							fill="url(#paint36_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.8"
							id="Rectangle 4.38"
							x="953.962"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 953.962 111.828)"
							fill="url(#paint37_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="3.9"
							id="Rectangle 4.39"
							x="973.428"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 973.428 121.561)"
							fill="url(#paint38_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4"
							id="Rectangle 4.40"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 983.16 131.294)"
							fill="url(#paint39_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.1"
							id="Rectangle 4.41"
							x="1012.36"
							y="141.027"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1012.36 141.027)"
							fill="url(#paint40_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.2"
							id="Rectangle 4.42"
							x="1031.82"
							y="141.027"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1031.82 141.027)"
							fill="url(#paint41_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.3"
							id="Rectangle 4.43"
							x="1051.29"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1051.29 121.561)"
							fill="url(#paint42_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.4"
							id="Rectangle 4.44"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 1061.02 141.027)"
							fill="url(#paint43_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.5"
							id="Rectangle 4.45"
							x="1090.22"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1090.22 102.095)"
							fill="url(#paint44_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.6"
							id="Rectangle 4.46"
							x="1109.69"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1109.69 111.828)"
							fill="url(#paint45_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.7"
							id="Rectangle 4.47"
							x="1129.15"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1129.15 121.561)"
							fill="url(#paint46_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.8"
							id="Rectangle 4.48"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 1138.89 131.294)"
							fill="url(#paint47_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="4.9"
							id="Rectangle 4.49"
							x="1168.08"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1168.08 102.095)"
							fill="url(#paint48_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5"
							id="Rectangle 4.50"
							x="1187.55"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1187.55 111.828)"
							fill="url(#paint49_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.1"
							id="Rectangle 4.51"
							x="1207.02"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1207.02 121.561)"
							fill="url(#paint50_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.9"
							id="Rectangle 4.52"
							x="1362.74"
							y="131.294"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="rotate(90 1362.74 131.294)"
							fill="url(#paint51_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6"
							id="Rectangle 4.67"
							x="1382.21"
							y="141.027"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1382.21 141.027)"
							fill="url(#paint52_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.8"
							id="Rectangle 4.53"
							width="48.6641"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 1333.54 131.294)"
							fill="url(#paint53_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.7"
							id="Rectangle 4.54"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 1314.08 121.561)"
							fill="url(#paint54_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.6"
							id="Rectangle 4.55"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 1294.61 141.027)"
							fill="url(#paint55_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.5"
							id="Rectangle 4.56"
							x="1284.88"
							y="131.294"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="rotate(90 1284.88 131.294)"
							fill="url(#paint56_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.2"
							id="Rectangle 4.57"
							x="1226.48"
							y="102.095"
							width="107.061"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1226.48 102.095)"
							fill="url(#paint57_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.3"
							id="Rectangle 4.58"
							x="1245.95"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1245.95 111.828)"
							fill="url(#paint58_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="5.4"
							id="Rectangle 4.59"
							x="1265.41"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1265.41 121.561)"
							fill="url(#paint59_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.1"
							id="Rectangle 4.60"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 1391.94 131.294)"
							fill="url(#paint60_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.2"
							id="Rectangle 4.61"
							x="1421.14"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1421.14 121.561)"
							fill="url(#paint61_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.3"
							id="Rectangle 4.62"
							x="1440.6"
							y="111.828"
							width="87.5954"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1440.6 111.828)"
							fill="url(#paint62_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.4"
							id="Rectangle 4.63"
							x="1460.07"
							y="121.561"
							width="68.1298"
							height="9.73282"
							rx="4.86641"
							transform="rotate(90 1460.07 121.561)"
							fill="url(#paint63_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.5"
							id="Rectangle 4.64"
							width="48.6641"
							height="9.73283"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 1469.8 131.294)"
							fill="url(#paint64_linear)"
						/>
						<rect
							class="animation _type_fadeIn"
							data-duration="0.1"
							data-delay="6.6"
							id="Rectangle 4.65"
							width="29.1985"
							height="9.73282"
							rx="4.86641"
							transform="matrix(-4.37114e-08 1 1 4.37114e-08 1489.27 141.027)"
							fill="url(#paint65_linear)"
						/>
					</g>
					<g id="Group 110">
						<circle id="Ellipse 64" cx="99.5" cy="99.5" r="99.5" fill="url(#paint66_linear)" />
						<g id="volume">
							<path
								id="Vector"
								d="M46.1762 136.87C47.7944 110.489 60.6309 88.4541 77.758 78.8648C78.0982 78.3523 78.4384 77.8398 78.7444 77.3102C55.1591 82.4938 36.3379 106.367 34.5389 136.194C32.7058 166.004 48.5064 191.973 71.3337 199.962C71.0596 199.4 70.7684 198.872 70.5455 198.292C54.6207 186.706 44.575 163.301 46.1762 136.87Z"
								fill="#18B3BD"
							/>
							<path
								id="Vector_2"
								d="M60.7313 137.761C62.0014 116.364 67.5582 97.0559 75.6158 82.5715C60.9311 93.5036 50.3598 113.555 48.9404 137.052C47.521 160.548 55.5659 181.722 68.8313 194.371C62.5896 178.995 59.41 159.176 60.7313 137.761Z"
								fill="#18B3BD"
							/>
							<path
								id="Vector_3"
								d="M64.448 136.372C66.3634 105.112 78.0461 77.8578 94.7654 60.6941C88.938 64.4425 83.5241 70.1008 78.8128 77.3444C78.4726 77.8569 78.1325 78.3695 77.8264 78.899C60.631 88.4543 47.7946 110.49 46.1763 136.87C44.558 163.25 54.6378 186.672 70.5114 198.275C70.7856 198.838 71.0597 199.4 71.2997 199.946C76.662 210.837 83.776 218.892 91.9734 222.8C73.4412 204.292 62.2858 172.154 64.448 136.372ZM48.9406 137.052C50.36 113.555 60.9313 93.5038 75.6159 82.5717C67.5584 97.0561 62.0016 116.364 60.7314 137.761C59.4102 159.176 62.5898 178.995 68.8315 194.371C55.5661 181.722 47.5212 160.548 48.9406 137.052Z"
								fill="#122A67"
							/>
							<path
								id="Vector_4"
								d="M68.4951 138.257C71.6977 85.5654 101.846 44.0619 137.158 41.6867C121.614 41.1554 106.87 48.2659 94.7652 60.6941C78.0459 77.8578 66.3632 105.111 64.4478 136.372C62.2856 172.154 73.441 204.292 92.0073 222.817C93.9734 224.775 96.0585 226.579 98.1775 228.23C78.3315 209.707 66.2175 175.985 68.4951 138.257Z"
								fill="#18B3BD"
							/>
							<path
								id="Vector_5"
								d="M136.044 239.908C129.628 239.528 123.397 237.835 117.522 234.912C89.8202 221.132 72.9701 182.454 75.6449 138.703C77.1629 113.381 85.0998 89.8886 97.9798 72.4769C111.694 53.9886 129.418 44.3981 147.863 45.5207C154.279 45.9 160.51 47.5935 166.385 50.5161C170.074 52.3512 173.542 54.6303 176.823 57.2852C167.216 48.0746 155.774 42.3823 143.232 41.6401C141.201 41.5248 139.188 41.546 137.158 41.6866C101.863 44.0277 71.6978 85.5653 68.4953 138.257C66.2177 175.985 78.3317 209.707 98.1606 228.264C107.579 237.082 118.749 242.468 130.983 243.228C143.354 243.97 155.254 239.834 165.799 231.999C156.496 237.767 146.384 240.535 136.044 239.908Z"
								fill="#122A67"
							/>
							<path
								id="Vector_6"
								d="M158.603 66.2455C154.811 64.3594 150.834 63.2756 146.824 63.0279C134.504 62.2681 122.166 69.3396 112.093 82.9068C101.204 97.6018 94.4548 117.765 93.1175 139.726C90.9051 175.951 104.16 208.665 125.304 219.183C129.095 221.069 133.073 222.153 137.082 222.401C149.402 223.16 161.74 216.089 171.813 202.522C182.702 187.827 189.452 167.664 190.789 145.703C192.984 109.426 179.746 76.7634 158.603 66.2455ZM128.784 177.324C115.918 176.549 106.467 159.916 107.655 140.14C108.877 120.38 120.277 105.002 133.143 105.777C146.008 106.553 155.46 123.186 154.272 142.962C153.05 162.722 141.633 178.134 128.784 177.324Z"
								fill="#122A67"
							/>
							<path
								id="Vector_7"
								d="M208.296 146.742C210.591 108.81 198.203 74.6955 176.807 57.3194C173.525 54.6645 170.057 52.3854 166.368 50.5503C160.459 47.6107 154.228 45.9173 147.847 45.5549C129.401 44.4323 111.694 53.9886 97.9629 72.5111C85.0488 89.9059 77.1119 113.398 75.5939 138.721C72.9191 182.471 89.7692 221.149 117.471 234.93C123.38 237.869 129.611 239.563 135.992 239.925C146.333 240.552 156.428 237.819 165.748 232.016C173.024 227.497 179.836 221.085 185.842 212.952C198.807 195.54 206.744 172.047 208.296 146.742ZM171.813 202.522C161.741 216.089 149.403 223.16 137.083 222.401C133.039 222.136 129.095 221.069 125.304 219.183C104.126 208.648 90.8885 175.986 93.1179 139.726C94.4552 117.765 101.205 97.6017 112.094 82.9068C122.167 69.3396 134.505 62.2681 146.824 63.0278C150.868 63.2926 154.812 64.3594 158.603 66.2455C179.781 76.7804 193.019 109.443 190.789 145.703C189.469 167.629 182.72 187.793 171.813 202.522Z"
								fill="#4ED9F9"
							/>
							<path
								id="Vector_8"
								d="M133.126 105.811C120.26 105.036 108.86 120.414 107.638 140.174C106.416 159.933 115.902 176.583 128.767 177.358C141.633 178.134 153.033 162.756 154.238 143.03C155.443 123.22 145.992 106.587 133.126 105.811Z"
								fill="#4ED9F9"
							/>
						</g>
					</g>
				</g>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="233.937"
						y1="152.675"
						x2="248.232"
						y2="152.675"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint1_linear"
						x1="252.793"
						y1="145.274"
						x2="278.702"
						y2="145.274"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint2_linear"
						x1="272.654"
						y1="134.829"
						x2="318.218"
						y2="134.829"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint3_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint4_linear"
						x1="311.665"
						y1="135.82"
						x2="355.518"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint5_linear"
						x1="331.159"
						y1="126.087"
						x2="392.552"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint6_linear"
						x1="350.625"
						y1="126.087"
						x2="412.018"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint7_linear"
						x1="0.181661"
						y1="4.52582"
						x2="114.198"
						y2="4.52583"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint8_linear"
						x1="389.668"
						y1="87.1552"
						x2="521.225"
						y2="87.1552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint9_linear"
						x1="409.078"
						y1="106.621"
						x2="505.553"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint10_linear"
						x1="428.515"
						y1="116.354"
						x2="507.449"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint11_linear"
						x1="0.0978174"
						y1="4.52582"
						x2="61.4912"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint12_linear"
						x1="467.391"
						y1="135.82"
						x2="511.243"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint13_linear"
						x1="486.828"
						y1="145.552"
						x2="513.14"
						y2="145.552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint14_linear"
						x1="506.294"
						y1="145.552"
						x2="532.605"
						y2="145.552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint15_linear"
						x1="0.0419217"
						y1="4.52582"
						x2="26.3534"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint16_linear"
						x1="545.253"
						y1="135.82"
						x2="589.105"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint17_linear"
						x1="564.775"
						y1="116.354"
						x2="643.709"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint18_linear"
						x1="584.296"
						y1="96.8879"
						x2="698.312"
						y2="96.8879"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint19_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint20_linear"
						x1="623.2"
						y1="106.621"
						x2="719.675"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint21_linear"
						x1="642.581"
						y1="135.82"
						x2="686.434"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint22_linear"
						x1="662.047"
						y1="135.82"
						x2="705.899"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint23_linear"
						x1="0.0978174"
						y1="4.52582"
						x2="61.4912"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint24_linear"
						x1="701.034"
						y1="116.354"
						x2="779.968"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint25_linear"
						x1="720.5"
						y1="116.354"
						x2="799.434"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint26_linear"
						x1="739.938"
						y1="126.087"
						x2="801.331"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint27_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint28_linear"
						x1="0.153713"
						y1="4.52582"
						x2="96.6291"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint29_linear"
						x1="0.125765"
						y1="4.52582"
						x2="79.0601"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint30_linear"
						x1="0.0978174"
						y1="4.52582"
						x2="61.4912"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint31_linear"
						x1="778.841"
						y1="135.82"
						x2="822.693"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint32_linear"
						x1="856.76"
						y1="116.354"
						x2="935.694"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint33_linear"
						x1="876.197"
						y1="126.087"
						x2="937.59"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint34_linear"
						x1="895.663"
						y1="126.087"
						x2="957.056"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint35_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint36_linear"
						x1="934.65"
						y1="106.621"
						x2="1031.13"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint37_linear"
						x1="954.088"
						y1="116.354"
						x2="1033.02"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint38_linear"
						x1="973.526"
						y1="126.087"
						x2="1034.92"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint39_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint40_linear"
						x1="1012.4"
						y1="145.552"
						x2="1038.71"
						y2="145.552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint41_linear"
						x1="1031.87"
						y1="145.552"
						x2="1058.18"
						y2="145.552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint42_linear"
						x1="1051.39"
						y1="126.087"
						x2="1112.78"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint43_linear"
						x1="0.0419217"
						y1="4.52582"
						x2="26.3534"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint44_linear"
						x1="1090.37"
						y1="106.621"
						x2="1186.85"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint45_linear"
						x1="1109.81"
						y1="116.354"
						x2="1188.75"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint46_linear"
						x1="1129.25"
						y1="126.087"
						x2="1190.64"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint47_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint48_linear"
						x1="1168.24"
						y1="106.621"
						x2="1264.71"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint49_linear"
						x1="1187.68"
						y1="116.354"
						x2="1266.61"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint50_linear"
						x1="1207.11"
						y1="126.087"
						x2="1268.51"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint51_linear"
						x1="1362.81"
						y1="135.82"
						x2="1406.66"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint52_linear"
						x1="1382.25"
						y1="145.552"
						x2="1408.56"
						y2="145.552"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint53_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint54_linear"
						x1="0.0978174"
						y1="4.52582"
						x2="61.4912"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint55_linear"
						x1="0.0419217"
						y1="4.52582"
						x2="26.3534"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint56_linear"
						x1="1284.95"
						y1="135.82"
						x2="1328.8"
						y2="135.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint57_linear"
						x1="1226.63"
						y1="106.621"
						x2="1323.11"
						y2="106.621"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint58_linear"
						x1="1246.07"
						y1="116.354"
						x2="1325.01"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint59_linear"
						x1="1265.51"
						y1="126.087"
						x2="1326.9"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint60_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint61_linear"
						x1="1421.24"
						y1="126.087"
						x2="1482.63"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint62_linear"
						x1="1440.73"
						y1="116.354"
						x2="1519.66"
						y2="116.354"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint63_linear"
						x1="1460.17"
						y1="126.087"
						x2="1521.56"
						y2="126.087"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint64_linear"
						x1="0.0698696"
						y1="4.52582"
						x2="43.9223"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint65_linear"
						x1="0.0419217"
						y1="4.52582"
						x2="26.3534"
						y2="4.52582"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#4ED9F9" />
						<stop offset="1" stop-color="#4ED9F9" stop-opacity="0" />
					</linearGradient>
					<linearGradient
						id="paint66_linear"
						x1="99.5"
						y1="0"
						x2="99.5"
						y2="199"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#881CA3" />
						<stop offset="1" stop-color="#6E32D6" stop-opacity="0" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'section_pr',
		data() {
			return {
				isMobile: false
			}
		},
		methods: {
			changeRender() {
				if (
					/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
						navigator.userAgent,
					)
				) {
					this.isMobile = true;
				}
				return this.isMobile;
			},
		},
		mounted() {
			this.changeRender();
		},
	};
</script>

<style lang="less" scoped>
	.section_pr {
		background: url(../assets/bg_abstrakt_5.png) center left no-repeat;
		#svg-container {
			height: 180px;
		}
		#svg {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -23%);
			width: 95%;

			svg {
				width: 100%;
			}
		}
	}
	@media (max-width: 768px) {
		.section_pr {
			&>div {
				&>div:nth-child(2) {
					margin-top: 6em;
				}
			}
			#svg-container {
				display: none;
			}
			.text-pink {
				margin-top: 1em;
			}
			#svg {
				top: 41%;

				svg {
					height: auto;
				}
			}
		}
	}
</style>
